<div class="center_img">
    <img (click)="closePopup()" src="../../../assets/images/Vector.png" alt="NsureHub">
</div>
<div class="kickoutScreen">
    <img src="../../assets/images/nsurehub/logo.png" alt="NsureHub" />
    <p *ngIf="!isBindQuoteKickOff && !isPaymentError">
        Thank you for your interest, but we need additional information.
    </p>
    <p *ngIf="isBindQuoteKickOff && !isPaymentError">
        Sorry, we are unable to bind your policy at this time.
    </p>
    <p *ngIf="!isBindQuoteKickOff && isPaymentError">
        Sorry, we are unable to process your payment.
    </p>
    <p>Please call one of our agents at NsureHub: <br>
        <a href="tel:+18444032444">1-844-403-2444</a>
    </p>
</div>